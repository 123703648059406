import { Link } from '@agent-zone/ui-library/Link';
import React, { MouseEvent, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  readonly to: string;
  readonly children: ReactNode;
}

export const NaviLink: React.FC<Props> = ({ to, children }) => {
  const history = useHistory();

  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault();
    history.push(to);
  };

  return (
    <Link // eslint-disable-line
      onClick={handleOnClick}
    >
      {children}
    </Link>
  );
};
