import { useParams } from 'react-router-dom';
import { ProgressNN } from '@agent-zone/ui-library/ProgressNN';
import React from 'react';
import { ActivateAccount } from '../ActivateAccount/ActivateAccount';
import { ActivateError } from '../ActivateError/ActivateError';
import { PasswordResetNew } from '../PasswordReset/SetNew/PasswordResetNew';
import { useToken } from './useToken';

export const TokenValidator: React.FC = () => {
  const { token } = useParams<{ token: string }>();

  const { tokenLoading, isReset, tokenError } = useToken(token);

  if (tokenLoading) {
    return <ProgressNN isFullScreen />;
  }

  if (tokenError) {
    return <ActivateError error={tokenError} isReset={isReset} />;
  }

  if (isReset) {
    return <PasswordResetNew token={token} />;
  }

  return <ActivateAccount token={token} />;
};
