import { ErrorMessage } from '@agent-zone/ui-library/ErrorMessage';
import { Button } from '@agent-zone/ui-library/Button';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Spacer } from '../../../ui/Margins/Spacer';
import { Typo } from '../../../ui/Typography/Typography';
import { Hook } from '../../useActivationSubmit';
import { InputPESEL } from '../../../ui/Form/InputPESEL';
import { InputPassword } from '../../../ui/Form/InputPassword';
import { InputPasswordRepeat } from '../../../ui/Form/InputPasswordRepeat';
import { InputPhone } from '../../../ui/Form/InputPhone';

interface Props {
  readonly formFieldsError: Hook['formFieldsError'];
  readonly values: Hook['values'];
  readonly onChangeFieldValue: Hook['onChangeFieldValue'];
  readonly isFormLoading: boolean;
}

export const ActivateAccountForm: React.FC<Props> = ({
  values,
  formFieldsError,
  onChangeFieldValue,
  isFormLoading,
}) => {
  const { t } = useTranslation();

  const peselError = formFieldsError.pesel?.messageKey
    ? t(formFieldsError.pesel.messageKey)
    : undefined;
  const phoneError = formFieldsError.phone?.messageKey
    ? t(formFieldsError.phone.messageKey)
    : undefined;

  return (
    <>
      <Typo $noMargin $type="H2">
        Aktywuj swój dostęp do konta
      </Typo>
      <Typo $type="H2">&quot;Mój start&quot;</Typo>

      <Spacer $size="L" />

      <InputPESEL
        caption="Numer PESEL służy nam do potwierdzenia Twojej tożsamości"
        disabled={isFormLoading}
        error={peselError}
        label="Podaj numer PESEL"
        onChange={(value) => onChangeFieldValue('pesel', value)}
        required
        type="text"
        value={values.pesel}
      />

      <Spacer $size="S" />

      <InputPhone
        caption="Wpisz numer na który otrzymałeś powiadomienie o możliwości aktywacji konta"
        disabled={isFormLoading}
        error={phoneError}
        label="Podaj numer telefonu"
        onChange={(value) => onChangeFieldValue('phone', value)}
        prefix="+48"
        required
        type="tel"
        value={values.phone}
      />

      <Spacer $size="S" />

      <InputPassword
        disabled={isFormLoading}
        isHideRequired
        label="Nadaj hasło"
        onChange={(value) => onChangeFieldValue('password', value)}
        required
        value={values.password}
      />

      <Spacer $size="S" />

      <InputPasswordRepeat
        basePassword={values.password}
        disabled={isFormLoading}
        label="Powtórz hasło"
        onChange={(value) => onChangeFieldValue('passwordRepeat', value)}
        required
        value={values.passwordRepeat}
      />

      <Spacer $size="L" />

      <div>
        <Button
          disabled={isFormLoading}
          type="submit"
          variant="deprecated-primary"
        >
          Aktywuj
        </Button>
      </div>
      {formFieldsError.lastName?.messageKey ? (
        <ErrorMessage>{t(formFieldsError.lastName.messageKey)}</ErrorMessage>
      ) : null}
    </>
  );
};
