import {
  FormEvent, useCallback, useEffect,
} from 'react';
import { notify } from '@agent-zone/ui-library/Toast';
import { apiPrefix } from '../../../api';
import { useFetch } from '../../../hooks/useFetch';
import { useForm } from '../../../hooks/useForm';

interface Hook {
  handleSubmit: (e:FormEvent) => void;
  isLoading: boolean;
  values: {
    newPassword: string;
    newPasswordRepeat: string;
  };
  successFirstName?: string;
  onChangeFieldValue: (fieldName: 'newPassword' | 'newPasswordRepeat', fieldValue: unknown) => void;
}

export function usePasswordResetNew(token: string): Hook {
  const { values, onChangeFieldValue } = useForm({ newPassword: '', newPasswordRepeat: '' });

  // submit
  const [
    formSubmit, successFirstName, isLoading, formError,
  ] = useFetch<{ firstName:string }, string>();

  const handleSubmit = useCallback<Hook['handleSubmit']>(async (e: FormEvent) => {
    e.preventDefault();

    if (values.newPassword === values.newPasswordRepeat) {
      await formSubmit(
        `${apiPrefix}/onboarding-auth/public/register/`,
        {
          method: 'POST',
          body: JSON.stringify({
            password: values.newPassword,
            token,
          }),
        },
        (data) => data.firstName,
      );
    }
  }, [formSubmit, values, token]);

  useEffect(() => {
    if (formError) {
      notify({
        variant: 'error',
        text: 'Podany PESEL i/lub hasło są nieprawidłowe. Popraw wprowadzone dane i spróbuj ponownie.',
      });
    }
  }, [formError]);

  return {
    values: {
      newPassword: `${values.newPassword}`,
      newPasswordRepeat: `${values.newPasswordRepeat}`,
    },
    successFirstName,
    isLoading,
    handleSubmit,
    onChangeFieldValue,
  };
}
