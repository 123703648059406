import { P } from '@agent-zone/ui-library/Text/P';
import styled from 'styled-components';

export const AboutWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  > div:last-child {
    margin-bottom: 100px;
  }
`;

export const AboutTypo = styled(P)`
  background: white;
  border-radius: 3px;
  padding: 8px 12px;
  text-align: left;
  display: inline-block;
`;

export const AboutTypoRow = styled.div`
  text-align: right;
  max-width: 95%;
  padding-left: 2%;
`;
