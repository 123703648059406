import { Button } from '@agent-zone/ui-library/Button';
import { useHistory } from 'react-router-dom';
import React from 'react';

interface Props {
  readonly variant?: 'primary' | 'secondary' | 'tertiary';
  readonly size?: 'S' | 'M' | 'L';
  readonly className?: string;
  readonly isDisabled?: boolean;

  readonly to: string;
}

export const ButtonTo: React.FC<Props> = ({ to, children, ...props }) => {
  const history = useHistory();
  let onClickHandler;
  if (to.startsWith('https')) {
    onClickHandler = () => window.location.replace(to);
  } else {
    onClickHandler = () => history.push(to);
  }

  return (
    <Button
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      onClick={onClickHandler}
      variant="deprecated-primary"
    >
      {children}
    </Button>
  );
};
