import { primaryColors } from '@agent-zone/ui-library/esm/colors';
import { Button } from '@agent-zone/ui-library/Button';
import { ToastContainer } from '@agent-zone/ui-library/Toast';
import styled from 'styled-components';
import React from 'react';
import { AboutTypo, AboutTypoRow, AboutWrapper } from '../ui/About/AboutTypo';
import { NaviLink } from '../ui/AdditionalButton/NaviLink';
import { Footer } from '../ui/Footer/Footer';
import { InputPassword } from '../ui/Form/InputPassword';
import { InputPESEL } from '../ui/Form/InputPESEL';
import { GoogleInfo } from '../ui/GoogleInfo';
import { Header } from '../ui/Header/Header';
import { MainContent } from '../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../ui/Margins/Margins';
import { Spacer } from '../ui/Margins/Spacer';
import { Typo } from '../ui/Typography/Typography';
import backgroundUrl from './background.jpg';
import { isKeyOfErrorTexts, LoginError } from './LoginError';
import { useLogin } from './useLogin';

const AboutTypoRow1 = styled(AboutTypoRow)`
  width: 62%;
  min-width: 380px;
`;

const AboutTypoRow2 = styled(AboutTypoRow)`
  width: 64%;
  min-width: 500px;
`;

const AboutTypoRow3 = styled(AboutTypoRow)`
  width: 90%;
  min-width: 450px;
  color: ${primaryColors.orangeDark};
`;

const LeftColumn = styled(PaddingsWrapper.withComponent('form'))`
  display: flex;
  flex-direction: column;
  width: 605px;
  background: ${primaryColors.white};
  padding-top: 100px;
  justify-content: center;
`;

const RightColumn = styled.div`
  flex: 1;
  background: url(${backgroundUrl});
  background-size: cover;
  position: relative;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Login: React.FC = () => {
  const {
    handleSubmit,
    isLoading,
    values,
    onChangeFieldValue,
    loginErrorCode,
  } = useLogin();

  if (loginErrorCode && isKeyOfErrorTexts(loginErrorCode)) {
    return <LoginError error={loginErrorCode} />;
  }

  return (
    <>
      <Header />
      <MainContent>
        <LeftColumn
          $paddingBottom="XL"
          $paddingsVertical="XL"
          onSubmit={handleSubmit}
        >
          <Typo $type="H2">Logowanie</Typo>

          <Spacer $size="S" />

          <InputPESEL
            disabled={isLoading}
            label="Podaj numer PESEL"
            onChange={(value) => onChangeFieldValue('login', value)}
            required
            type="login"
            value={values.login as string}
          />

          <Spacer $size="S" />

          <InputPassword
            disabled={isLoading}
            isHideDescription
            isHideFeedback
            label="Hasło"
            onChange={(value) => onChangeFieldValue('password', value)}
            required
            value={values.password as string}
          />

          <Spacer $size="L" />

          <GoogleInfo />

          <Spacer $size="L" />

          <ButtonsWrapper>
            <Button
              disabled={isLoading}
              type="submit"
              variant="deprecated-primary"
            >
              Zaloguj
            </Button>
            <NaviLink to="/password-reset">Nie pamiętam hasła</NaviLink>
          </ButtonsWrapper>
        </LeftColumn>
        <RightColumn>
          <AboutWrapper>
            <AboutTypoRow1>
              <AboutTypo font="h1" textColor="orangeMedium">
                Jesteś tu, ponieważ
              </AboutTypo>
            </AboutTypoRow1>
            <AboutTypoRow2>
              <AboutTypo font="h1" textColor="orangeMedium">
                zaczynasz nową przygodę.
              </AboutTypo>
            </AboutTypoRow2>
            <AboutTypoRow3>
              <AboutTypo font="h1" textColor="orangeDark">
                Zaloguj się i startujemy!
              </AboutTypo>
            </AboutTypoRow3>
          </AboutWrapper>
        </RightColumn>
        <ToastContainer />
      </MainContent>
      <Footer />
    </>
  );
};
