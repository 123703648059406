import { primaryColors, nnColors } from '@agent-zone/ui-library/esm/colors';
import styled, { css } from 'styled-components';

interface Props {
  progress: number;
}

const Wrapper = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  ${({ $progress }: { $progress: number }) => ($progress === 5 ? css`> div{ background: ${nnColors.greenDark}; }` : css``)}
`;

const Segment = styled.div`
  width: calc(90%/5);
  height: 2px;
  ${({ $isActive }: { $isActive: boolean }) => ($isActive ? css`background: ${primaryColors.orangeMedium};` : css`background: ${primaryColors.greyLight};`)}
`;

export const PasswordProgressBar:React.FC<Props> = ({ progress }) => (
  <Wrapper $progress={progress}>
    <Segment $isActive={progress > 0} />
    <Segment $isActive={progress > 1} />
    <Segment $isActive={progress > 2} />
    <Segment $isActive={progress > 3} />
    <Segment $isActive={progress > 4} />
  </Wrapper>
);
