import { InfoTip } from '@agent-zone/ui-library/InfoTip';
import styled from 'styled-components';
import { Typo } from './Typography/Typography';

const A = styled.a`
  text-decoration: none;
`;

export const GoogleInfo: React.FC = () => (
  <InfoTip icon="infoRound">
    <Typo $type="H6">
      Usługa jest chroniona przez reCAPTCHA,
      <A href="https://policies.google.com/privacy" target="_blank">
        <Typo $inline $type="body"> Politykę Prywatności </Typo>
      </A>
      i
      <A href="https://policies.google.com/terms" target="_blank">
        <Typo $inline $type="body"> Warunki Usługi </Typo>
      </A>
      przez Google.
    </Typo>
  </InfoTip>
);
