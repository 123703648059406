import { Input } from '@agent-zone/ui-library/esm/Input';
import { useCallback, useState } from 'react';

type Props = React.ComponentProps<typeof Input>;

export const InputPESEL: React.FC<Props> = ({
  value, error, ...props
}) => {
  const [blurred, setBlurred] = useState(false);
  let localFieldError: string | undefined;

  if (blurred) {
    if (`${value}`.length === 0) {
      localFieldError = 'Uzupełnij to pole';
    } else if (`${value}`.length !== 11) {
      localFieldError = 'Niepoprawne dane. Właściwy format zawiera 11 cyfr.';
    }
  }

  const handleBlur = useCallback(() => {
    setBlurred(true);
  }, []);

  return (
    <Input
      {...props} // eslint-disable-line
      error={localFieldError || error}
      keyPattern="[0-9]"
      maxLength={11}
      onBlur={handleBlur}
      pattern="[0-9]{11}"
      value={value}
    />
  );
};
