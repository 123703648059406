import { PasswordInput } from '@agent-zone/ui-library/esm/PasswordInput';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FieldInvalid } from '../../../hooks/useFetch';

const Wrapper = styled.div`
  position: relative;
`;

interface Props extends React.ComponentProps<typeof PasswordInput> {
  readonly fieldError?: FieldInvalid;
  readonly feedbackInfo?: string;
  readonly basePassword: string;
}

export const InputPasswordRepeat: React.FC<Props> = ({
  fieldError,
  feedbackInfo,
  basePassword,
  ...props
}) => {
  const [blurred, setBlurred] = useState(false);
  let localFieldError: string | undefined;

  if (blurred) {
    if (`${props.value}`.length === 0) {
      localFieldError = 'Wprowadź ponownie hasło';
    } else if (`${props.value}` !== basePassword) {
      localFieldError = 'Hasła nie są zgodne';
    }
  }

  const handleBlur = useCallback(() => {
    setBlurred(true);
  }, []);

  const error = localFieldError || fieldError?.messageKey || undefined;

  return (
    <Wrapper>
      <PasswordInput
        error={error}
        onBlur={handleBlur}
        {...props} // eslint-disable-line
      />
    </Wrapper>
  );
};
