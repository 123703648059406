import { primaryColors } from '@agent-zone/ui-library/esm/colors';
import { Button } from '@agent-zone/ui-library/Button';
import { notify, ToastContainer } from '@agent-zone/ui-library/Toast';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
  AboutTypo,
  AboutTypoRow,
  AboutWrapper,
} from '../../ui/About/AboutTypo';
import { Footer } from '../../ui/Footer/Footer';
import { InputPassword } from '../../ui/Form/InputPassword';
import { InputPasswordRepeat } from '../../ui/Form/InputPasswordRepeat';
import { Header } from '../../ui/Header/Header';
import { MainContent } from '../../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../../ui/Margins/Margins';
import { Spacer } from '../../ui/Margins/Spacer';
import { Typo } from '../../ui/Typography/Typography';
import backgroundUrl from '../background.jpg';
import { usePasswordResetNew } from './usePasswordResetNew';

const AboutTypoRow1 = styled(AboutTypoRow)`
  width: 50%;
  min-width: 450px;
`;

const AboutTypoRow2 = styled(AboutTypoRow)`
  width: 94%;
  min-width: 450px;
  max-width: 1000px;
  color: ${primaryColors.orangeDark};
`;

const LeftColumn = styled(PaddingsWrapper.withComponent('form'))`
  display: flex;
  flex-direction: column;
  width: 605px;
  background: ${primaryColors.white};
  padding-top: 100px;
  justify-content: center;
`;

const RightColumn = styled.div`
  flex: 1;
  background: url(${backgroundUrl});
  background-size: cover;
  position: relative;
  transform: scaleX(-1);
`;

const RevertTransform = styled.div`
  transform: scaleX(-1);
  flex: 1;
  width: 100%;
  height: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  token: string;
}

export const PasswordResetNew: React.FC<Props> = ({ token }) => {
  const {
    handleSubmit,
    isLoading,
    values,
    onChangeFieldValue,
    successFirstName,
  } = usePasswordResetNew(token);

  useEffect(() => {
    if (successFirstName) {
      notify({
        text: 'Hasło zostało poprawnie zmienione. Zaloguj się za pomocą nowego hasła.',
        variant: 'success',
      });
    }
  }, [successFirstName]);

  if (successFirstName) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Header />
      <MainContent>
        <LeftColumn
          $paddingBottom="XL"
          $paddingsVertical="XL"
          onSubmit={handleSubmit}
        >
          <Typo $type="H2">Odzyskiwanie hasła</Typo>

          <Spacer $size="S" />

          <InputPassword
            disabled={isLoading}
            label="Nadaj hasło"
            onChange={(value) => onChangeFieldValue('newPassword', value)}
            required
            value={values.newPassword}
          />

          <Spacer $size="M" />

          <InputPasswordRepeat
            basePassword={values.newPassword}
            disabled={isLoading}
            label="Powtórz hasło"
            onChange={(value) => onChangeFieldValue('newPasswordRepeat', value)}
            required
            value={values.newPasswordRepeat}
          />

          <Spacer $size="L" />

          <ButtonsWrapper>
            <Button
              disabled={isLoading}
              type="submit"
              variant="deprecated-primary"
            >
              Zmień hasło
            </Button>
          </ButtonsWrapper>
        </LeftColumn>
        <RightColumn>
          <RevertTransform>
            <AboutWrapper>
              <AboutTypoRow1>
                <AboutTypo font="h1">To nie koniec świata</AboutTypo>
              </AboutTypoRow1>
              <AboutTypoRow2>
                <AboutTypo font="h1">Każdemu może się zdarzyć</AboutTypo>
              </AboutTypoRow2>
            </AboutWrapper>
          </RevertTransform>
        </RightColumn>
        <ToastContainer />
      </MainContent>
      <Footer />
    </>
  );
};
