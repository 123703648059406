import { primaryColors } from '@agent-zone/ui-library/esm/colors';
import { Logo } from '@agent-zone/ui-library/Logo';
import styled from 'styled-components';
import { P } from '@agent-zone/ui-library/Text/P';
import { A } from '@agent-zone/ui-library/Text/A';
import React from 'react';
import { Icon } from '@agent-zone/ui-library/Icon';
import { Span } from '@agent-zone/ui-library/Text/Span';
import { Flex } from '@agent-zone/ui-library/Flex';
import { PaddingsWrapper } from '../Margins/Margins';
import { Spacer } from '../Margins/Spacer';

const FooterWrapper = styled(PaddingsWrapper.withComponent('footer'))`
  background: ${primaryColors.orangeLight};
  display: flex;
  position: sticky;
  bottom: 0;
`;

const FooterLeftColumn = styled.div`
  display: flex;
  width: 350px;
  flex-direction: column;
`;

const FooterRightColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
`;

export const Footer: React.FC = () => (
  <FooterWrapper $paddingsHorizontal="L" $paddingsVertical="XL">
    <FooterLeftColumn>
      <Logo variant="basic">Logo</Logo>
      <Spacer $size="M" />
      <P font="caption" textColor="white">
        © 2021 Nationale-Nederlanden. Wszelkie prawa zastrzeżone.
      </P>
    </FooterLeftColumn>
    <FooterRightColumn>
      <A href="https://nn.pl" justifySelfCenter textColor="white">
        <Flex gapX="S" itemsCenter>
          <Icon icon="arrow-right" iconColor="white" strokeWidth={2} />
          <Span font="h6">nn.pl</Span>
        </Flex>
      </A>
      <Spacer $size="M" />
      <P font="h6" textColor="white">
        Masz problem z logowaniem? Napisz:&nbsp;&nbsp;&nbsp;
        <A
          font="body-bold"
          href="mailto:nnakademia@nn.pl"
          textColor="white"
          whitespace="nowrap"
        >
          nnakademia@nn.pl
        </A>
      </P>
    </FooterRightColumn>
  </FooterWrapper>
);
