import styled from 'styled-components';
import { FetchError } from '../../hooks/useFetch';
import { isErrorK2 } from '../../interface';
import { ButtonTo } from '../ui/ButtonTo/ButtonTo';
import { Footer } from '../ui/Footer/Footer';
import { Header } from '../ui/Header/Header';
import { MainContent } from '../ui/MainCotnent/MainContent';
import { PaddingsWrapper } from '../ui/Margins/Margins';
import { Spacer } from '../ui/Margins/Spacer';
import { Typo } from '../ui/Typography/Typography';
import dogSrc from './dog.png';

const RightColumn = styled(PaddingsWrapper.withComponent('div'))`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 72px;
`;

const TextWrapper = styled.div`
  max-width: 545px;
`;

interface Props {
  error: FetchError;
  isReset?: boolean;
}

const errorTextsExpire = {
  activate: (
    <>
      <Typo $color="orangeMedium" $type="H1">Twój link aktywacyjny wygasł</Typo>
      <Typo $type="H5">
        Próbujesz wykorzystać nieaktywny link do aplikacji.
        <br />
        Mamy dla ciebie trzy możliwe rozwiązania tego problemu:
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Aby aktywować konto odszukaj najnowszą wiadomość z linkiem aktywacyjnym.
        <br />
        Jeśli konto zostało już aktywowane,
        przejdź do strony logowania za pomocą poniższego przycisku i zaloguj się.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        W pozostałych przypadkach skontaktuj się ze swoim menedżerem.
      </Typo>
      <Spacer $size="XL" />
      <div>
        <ButtonTo to="/">Przejdź do strony logowania</ButtonTo>
      </div>
    </>
  ),
  reset: (
    <>
      <Typo $color="orangeMedium" $type="H1">Twój link do odzyskania hasła wygasł</Typo>
      <Typo $type="H5">
        Próbujesz wykorzystać nieaktywny link do aplikacji.
        <br />
        Upewnij się, że widomość e-mail z której korzystasz jest najnowsza.
        <br />
        Aby rozpocząć nowy proces odzyskiwania hasła przejdź do strony logowania.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Jeśli Twój problem jest innego typu skontaktuj się ze swoim menedżerem.
      </Typo>
      <Spacer $size="XL" />
      <div>
        <ButtonTo to="/">Przejdź do strony logowania</ButtonTo>
      </div>
    </>
  ),
};

const errorTexts = {
  UNKNOWN: (
    <>
      <Typo $color="orangeMedium" $type="H1">Ups... mamy problem</Typo>
      <Typo $type="H5">
        Wystąpił nieoczekiwany problem z aplikacją.
        <br />
        Ponów operację za chwilkę.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Jeśli problem wstąpi ponownie skontaktuj się ze swoim menedżerem.
      </Typo>
      <Spacer $size="XL" />
      <div>
        <ButtonTo to="/">Przejdź do strony logowania</ButtonTo>
      </div>
    </>
  ),
  'TOKEN-NOT-FOUND': (
    <>
      <Typo $color="orangeMedium" $type="H1">Użyty link jest niewłaściwy</Typo>
      <Typo $type="H5">
        Mamy problem z poprawną interpretacją linku z którego próbujesz wykonać kolejną akcje.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Spróbuj ponownie upewniając się, że wykorzystany
        link jest zgodny z tym, który został wysłany na Twój adres e-mail.
      </Typo>
      <Spacer $size="XL" />
      <div>
        <ButtonTo to="/">Przejdź do strony logowania</ButtonTo>
      </div>
    </>
  ),
  'TOKEN-IS-EXPIRED': '',
  'MAX-ATTEMPTS-REACHED': (
    <>
      <Typo $color="orangeMedium" $type="H1">Twój link aktywacyjny został zablokowany</Typo>
      <Typo $type="H5">
        Przykro nam, ale ten link został zablokowany ponieważ
        przekroczono dopuszczalną ilość prób aktywacji konta.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Robimy tak, ponieważ zależy nam na tym aby konta naszych użytkowników były bezpieczne.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Skontaktuj się ze swoim menedżerem w celu ustalenia dalszych kroków.
      </Typo>
    </>
  ),
  'TOKEN-IS-BLOCKED': (
    <>
      <Typo $color="orangeMedium" $type="H1">Twój link aktywacyjny został zablokowany</Typo>
      <Typo $type="H5">
        Przykro nam, ale ten link został zablokowany ponieważ
        przekroczono dopuszczalną ilość prób aktywacji konta.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Robimy tak, ponieważ zależy nam na tym aby konta naszych użytkowników były bezpieczne.
      </Typo>
      <Spacer $size="M" />
      <Typo $type="H5">
        Skontaktuj się ze swoim menedżerem w celu ustalenia dalszych kroków.
      </Typo>
    </>
  ),
  'TOKEN-IS-USED': (
    <>
      <Typo $color="orangeMedium" $type="H1">Twój link aktywacyjny wygasł</Typo>
      <Typo $type="H5">
        Próbujesz wykorzystać nieaktywny link do aplikacji.
        <br />
        Mamy dla ciebie dwa możliwe rozwiązania tego problemu:
      </Typo>
      <Spacer $size="S" />
      <Typo $type="H5">
        <ul>
          <li>
            Aby aktywować konto odszukaj najnowszą wiadomość z linkiem aktywacyjnym.
          </li>
          <li>
            Jeśli konto zostało już aktywowane,
            przejdź do strony logowania za pomocą poniższego przycisku i zaloguj się.
          </li>
        </ul>
      </Typo>
      <Spacer $size="S" />
      <Typo $type="H5">
        W pozostałych przypadkach skontaktuj się ze swoim menedżerem.
      </Typo>
      <Spacer $size="XL" />
      <div>
        <ButtonTo to="/">Przejdź do strony logowania</ButtonTo>
      </div>
    </>
  ),
};
function isKeyOfErrorTexts(key?: string): key is keyof typeof errorTexts {
  if (!key) {
    return false;
  }
  return Object.keys(errorTexts).includes(key);
}

export const ActivateError:React.FC<Props> = ({ error, isReset }) => {
  let errorTextName: keyof typeof errorTexts = 'UNKNOWN';

  if (isErrorK2(error) && error.errors[0] && isKeyOfErrorTexts(error.errors[0].messageKey)) {
    errorTextName = error.errors[0].messageKey as keyof typeof errorTexts;
  }

  return (
    <>
      <Header />
      <MainContent>
        <LeftColumn>
          <img alt="dog" src={dogSrc} />
        </LeftColumn>
        <RightColumn $paddingsHorizontal="XL">
          <TextWrapper>
            {errorTextName === 'TOKEN-IS-EXPIRED' && isReset ? errorTextsExpire.reset : null}
            {errorTextName === 'TOKEN-IS-EXPIRED' && !isReset && errorTextsExpire.activate}
            {errorTextName !== 'TOKEN-IS-EXPIRED' && errorTexts[errorTextName]}
          </TextWrapper>
        </RightColumn>
      </MainContent>
      <Footer />
    </>
  );
};
