import { FormEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { notify } from '@agent-zone/ui-library/Toast';
import { apiPrefix } from '../../api';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';

interface Hook {
  handleSubmit: (e: FormEvent) => void;
  isLoading: boolean;
  values: {
    login: string;
    password: string;
  };
  onChangeFieldValue: (
    fieldName: 'login' | 'password',
    fieldValue: unknown
  ) => void;
  loginErrorCode?: string;
}

export function useLogin(): Hook {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { values, onChangeFieldValue } = useForm({
    login: '',
    password: '',
  });

  // submit
  const [formSubmit, successData, isLoading, formError] = useFetch<
  unknown,
  any
  >();

  const handleSubmit = useCallback<Hook['handleSubmit']>(
    async (e: FormEvent) => {
      e.preventDefault();
      if (executeRecaptcha) {
        const recaptchaResponse = await executeRecaptcha();
        await formSubmit(`${apiPrefix}/onboarding-auth/login`, {
          method: 'POST',
          credentials: 'include',
          redirect: 'error',
          body: JSON.stringify({
            login: values.login,
            password: values.password,
            recaptchaResponse,
          }),
        });
      }
    },
    [formSubmit, values, executeRecaptcha],
  );

  useEffect(() => {
    // only if empty error object
    if (typeof formError === 'object' && !('errorCode' in formError)) {
      notify({
        variant: 'error',
        text: 'Podany PESEL i/lub hasło są nieprawidłowe. Popraw wprowadzone dane i spróbuj ponownie.',
      });
    }
  }, [formError]);

  useEffect(() => {
    if (successData !== undefined) {
      window.location.replace(apiPrefix);
    }
  }, [successData]);

  return {
    values,
    isLoading,
    handleSubmit,
    onChangeFieldValue,
    loginErrorCode:
      typeof formError === 'object' && 'errorCode' in formError
        ? (formError as any).errorCode
        : undefined,
  };
}
